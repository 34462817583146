import React, { useContext, useEffect } from 'react';
import { history } from '../../../root.component';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { PageHeaderMui } from 'components';
import { PrebuiltInstallsNavigationTabs } from './installs-navigationTabs.component';
import { Icon } from '../../../components/icon/icon.component';
export const PrebuiltInstall = () => {
  return <>
      <div className="wrapper" style={{
      height: 'auto'
    }}>
        <PageHeaderMui type="Prebuilt" icon={<Icon name="custom-segment" size={24} />} />

        <Stack spacing="20px" sx={{
        p: '20px'
      }}>
          <Box sx={{
          width: '100%'
        }}>
            <PrebuiltInstallsNavigationTabs currentTab={window?.location?.pathname.split('/')[3]} />
          </Box>

          <Stack spacing="20px" sx={{
          p: '20px'
        }}>
            <Typography sx={{
            fontSize: '24px'
          }}>Coming soon...</Typography>
          </Stack>
        </Stack>
      </div>
    </>;
};