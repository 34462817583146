import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import { Button, Grid, InputLabel } from '@mui/material';
import { FormikSelect } from 'components/mui/formik/formikselect.component';
import { FormikTextField } from 'components/mui/formik/formiktextfield.component';
import { deleteNotificationContact, deleteNotificationContactScope, getLocations, getMarkets } from 'shared/common.api';
import { useFormikContext } from 'formik';
import { FormikMultipleSelectCheckmarks } from 'components/mui/formik/formikmultipleselect.component';
const useStyles = makeStyles(() => ({
  itemContainer: {
    padding: '1rem'
  }
}));
export const NotificationContactForm = ({
  contact,
  submitForm,
  showMarket,
  setShowMarket,
  showLocation,
  setShowLocation,
  asCompany,
  history
}) => {
  const classes = useStyles();
  const {
    setFieldValue
  } = useFormikContext();
  const [availableMarkets, setAvailableMarkets] = useState([]);
  const [availableLocations, setAvailableLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getMarketLocationData = async () => {
      const marketsResponse = await getMarkets({
        company: asCompany.id
      });
      const markets = marketsResponse.data.results;
      const marketOptions = [];
      for (const market of markets) {
        marketOptions.push({
          value: market.id,
          name: market.name
        });
      }
      setAvailableMarkets(marketOptions);
      const locationsResponse = await getLocations({
        company: asCompany.id,
        limit: 800
      });
      const locations = locationsResponse.data.results;
      const locationOptions = [];
      for (const location of locations) {
        locationOptions.push({
          value: location.id,
          name: location.name
        });
      }
      setAvailableLocations(locationOptions);
      setLoading(false);
    };
    getMarketLocationData();
  }, [asCompany.id]);
  const deleteItem = async () => {
    if (contact) {
      try {
        const promises = [];
        for (const scope of contact.scope) {
          promises.push(deleteNotificationContactScope(contact.id, scope.id));
        }
        await Promise.all(promises); // TODO some sort of validation
        await deleteNotificationContact(contact.id);
        history.push('/notification-list');
      } catch (error) {
        console.error(error, 'Exception thrown attempting to delete recipient');
      }
    } else {
      history.push('/notification-list');
    }
  };

  // would we want to log the originally chosen markets / locations? (probably)
  const handleScopeChange = newScope => {
    if (newScope === 'company') {
      setShowMarket(false);
      setShowLocation(false);
      setFieldValue('market', [], true);
      setFieldValue('location', [], true);
    } else if (newScope === 'market') {
      setShowMarket(true);
      setShowLocation(false);
      setFieldValue('location', [], true);
    } else if (newScope === 'location') {
      setShowMarket(false);
      setShowLocation(true);
      setFieldValue('market', [], true);
    }
  };
  return <>
      {!loading && <>
          <Grid container className={classes.itemContainer}>
            <Grid item xs={5}>
              <Grid item xs={12} className={classes.itemContainer}>
                <InputLabel>Recipient Information</InputLabel>
              </Grid>
              <Grid item xs={12} className={classes.itemContainer}>
                <FormikTextField name="first_name" label="First Name" />
              </Grid>
              <Grid item xs={12} className={classes.itemContainer}>
                <FormikTextField name="last_name" label="Last Name" />
              </Grid>
              <Grid item xs={12} className={classes.itemContainer}>
                <FormikTextField name="email" label="Email" />
              </Grid>
              <Grid item xs={12} className={classes.itemContainer}>
                <FormikTextField name="phone" label="Phone" />
              </Grid>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <Grid item xs={12} className={classes.itemContainer}>
                <InputLabel>Recipient Configuration</InputLabel>
              </Grid>
              <Grid item xs={12} className={classes.itemContainer}>
                <FormikSelect name="scope" label="Scope" options={[{
              value: 'company',
              name: 'Company'
            }, {
              value: 'market',
              name: 'Markets'
            }, {
              value: 'location',
              name: 'Locations'
            }]} onChange={value => {
              handleScopeChange(value);
            }} />
              </Grid>
              {showMarket && <Grid item xs={12} className={classes.itemContainer}>
                  <FormikMultipleSelectCheckmarks name="market" options={availableMarkets} label="Available Markets" />
                </Grid>}
              {showLocation && <Grid item xs={12} className={classes.itemContainer}>
                  <FormikMultipleSelectCheckmarks name="location" options={availableLocations} label="Available Locations" />
                </Grid>}
              <Grid item xs={12} className={classes.itemContainer}>
                <FormikSelect name="alert_level" label="Alert Level" options={[{
              value: 'low',
              name: 'Low'
            }, {
              value: 'medium',
              name: 'Medium'
            }, {
              value: 'high',
              name: 'High'
            }]} />
              </Grid>
            </Grid>
            <Grid item xs={1} />
          </Grid>

          <Grid container>
            <Grid item xs={12} className={classes.itemContainer}>
              {/* <Divider /> */}
              <Grid item xs={12} style={{
            paddingBottom: '1rem'
          }}>
                <Grid container direction="row-reverse">
                  <Button onClick={submitForm}>Save</Button>
                  <Button onClick={deleteItem}>{contact ? 'Delete' : 'Back'}</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>}
    </>;
};