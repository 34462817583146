import numeral from 'numeral';
import { Container, Sprite, TextStyle, Text, Graphics } from 'pixi.js';
import { buildSquare, buildBorder, MODES, PROCESS } from './node-utils';
import { NodeDelete } from './node-delete';
import { NodeSave } from './node-save';
import { NodeDuplicate } from './node-duplicate';
import { NodeValid } from './node-valid';
import { NodeNumber } from './node-number';
import { NodeNumberDual } from './node-number-dual';
import { NodeConnect } from './node-connect';
import { updateNode, updateLink } from 'shared/common.api';
let sx, sy;
export class Node {
  static asCompany; // see useEffect in the file src/pages/customer-journeys/customer-journeys.component.js for where we're setting this.
  static companyFields; // same as asCompany
  static isSuper; // We want to hide the save button from people that aren't super users.

  isNodeFrom = false;
  constructor(node, initialPosition = {
    x: 0,
    y: 0
  }, hasLinks = false, mode = MODES.PAN, snapToGrid = true, trigger = () => {}) {
    this.id = null;
    this.type = node.type;
    this.subType = node.subType;
    this.templateId = node.templateId;
    this.name = node.name;
    this.img = node.img;
    this.description = node.description;
    this.component = node.component;
    this.componentParams = node.componentParams;
    this.color = node.color;
    this.colorShade = node.colorShade;
    this.defaultParameters = node.defaultParameters;
    this.modalWidth = node.modalWidth;
    this.parameters = node.parameters;
    this.connectFrom = node.connectFrom;
    this.connectTo = node.connectTo;
    this.mode = mode;
    this.snapToGrid = snapToGrid;
    this.onEventList = node.onEventList;
    this.getEvent = node.getEvent;
    this.getEventList = node.getEventList;
    this.noLink = node.noLink;
    this._isValidated = node.validate;
    this.buildLabel = node.buildLabel;
    this.initialPosition = initialPosition;
    this.hasLinks = hasLinks;
    this.trigger = trigger;
    this.build();
  }
  setSnapToGrid = snapToGrid => {
    this.snapToGrid = snapToGrid;
  };
  setMode = mode => {
    this.mode = mode;
    this.onMouseOut();
  };
  build = () => {
    this.buildContainer();
    this.buildShape();
    this.buildDelete();
    if (Node.isSuper) {
      this.buildSave();
    }
    this.buildDuplicate();
    this.buildValid();
    if (!this.noLink) {
      this.buildConnect();
    }
    this.buildNodeContent();
  };
  buildContainer = () => {
    this.container = new Container();
    this.container.movement = null;
    this.container.x = this.initialPosition.x;
    this.container.y = this.initialPosition.y;
    this.container.interactive = true;
    this.container.on('mouseover', this.onMouseOver).on('mouseout', this.onMouseOut).on('pointerdown', this.onDragStart).on('pointerup', this.onDragEnd).on('pointerupoutside', this.onDragEnd).on('pointermove', this.onDragMove);
  };
  buildShape = () => {
    this.shape = buildSquare(`0x${this.color}`, 124);
    this.container.addChild(this.shape);
    this.shapeBorder = buildBorder(124);
    this.shapeBorder.alpha = 0;
    this.shape.addChild(this.shapeBorder);
  };
  buildDelete = () => {
    this.nodeDelete = new NodeDelete(this.onDelete);
    this.nodeDelete.container.x = -6;
    this.nodeDelete.container.y = -6;
    this.container.addChild(this.nodeDelete.container);
  };
  buildSave = () => {
    this.nodeSave = new NodeSave(this.onSave);
    this.nodeSave.container.x = -6;
    this.nodeSave.container.y = 45;
    this.container.addChild(this.nodeSave.container);
  };
  buildDuplicate = () => {
    this.nodeDuplicate = new NodeDuplicate(this.onDuplicate);
    this.nodeDuplicate.container.x = -6;
    this.nodeDuplicate.container.y = 100;
    this.container.addChild(this.nodeDuplicate.container);
  };
  buildValid = () => {
    this.nodeValid = new NodeValid();
    this.nodeValid.container.x = 104;
    this.nodeValid.container.y = -6;
    this.container.addChild(this.nodeValid.container);
    this.nodeValid.container.alpha = this.isValidated() ? 0 : 1;
  };
  buildNodeNumber = () => {
    this.numberStats = new NodeNumberDual('', '', this.color, this.hasLinks);
    this.numberStats.container.position = {
      x: -5,
      y: -5
    };
    this.numberPercentage = new NodeNumber('', this.color);
    this.numberPercentage.container.position = {
      x: 124 - this.numberPercentage.container.width + 5,
      y: -5
    };
    this.numberStats.container.interactive = true;
    this.numberStats.container.mouseover = () => {
      if (this.stats) {
        this.numberStats.update(numeral(this.stats[0]).format('0,0'), numeral(this.stats[1]).format('0,0'));
      }
    };
    this.numberStats.container.mouseout = () => {
      if (this.stats) {
        this.numberStats.update(numeral(this.stats[0]).format('0a'), numeral(this.stats[1]).format('0a'));
      }
    };
    this.numberStats.container.on('pointerup', this.onClick);
    this.container.addChild(this.numberPercentage.container);
    this.container.addChild(this.numberStats.container);
  };
  onClick = event => {
    if (event) {
      this.container.dragging = false;
      this.trigger('OPEN_CUSTOMER_LIST_SQL', this);
    }
  };
  updateStats = (countIn, countOut) => {
    if (!this.numberStats) {
      this.buildNodeNumber();
    }
    this.stats = [countIn, countOut];
    this.numberStats.update(numeral(countIn).format('0a'), numeral(countOut).format('0a'));
    this.numberPercentage.update(`${countIn === 0 && countOut === 0 ? 0 : Math.round(countOut * 100 / countIn)}%`);
    this.numberPercentage.container.position = {
      x: 124 - this.numberPercentage.container.width + 5,
      y: -5
    };
    if (!this.hasLinks) {
      this.container.removeChild(this.numberPercentage.container);
    }
  };
  on = (event, obj, ...args) => {
    if (event === 'START_POINT_LINK') {
      this.isNodeFrom = true;
    } else if (event === 'END_POINT_LINK') {
      this.isNodeFrom = false;
    }
    this.trigger(event, obj, ...args);
  };
  buildConnect = () => {
    this.nodeConnect = new NodeConnect(this, (...args) => this.on(...args));
    this.nodeConnect.container.x = 106;
    this.nodeConnect.container.y = 43;
    this.container.addChild(this.nodeConnect.container);
  };
  trimLabelText = (labelText, ellipse) => {
    if (labelText) {
      this.label.text = labelText + (ellipse ? '...' : '');
      if (this.label.height > 28) {
        const splitText = labelText.split('');
        return this.trimLabelText(splitText.splice(0, splitText.length - 1).join(''), true);
      }
    } else {
      this.label.text = labelText;
    }
  };
  buildNodeContent = () => {
    this.icon = new Sprite.from(this.img);
    this.icon.alpha = 0.7;
    this.icon.scale.x = 0.5;
    this.icon.scale.y = 0.5;
    const style = {
      wordWrap: true,
      wordWrapWidth: 114,
      align: 'center',
      fontSize: 12,
      fontWeight: 400,
      fill: '#ffffff'
    };
    this.text = new Text(this.name || this.parameters?.name || 'Unknown Node', new TextStyle({
      ...style,
      fontWeight: 700,
      fontSize: 13
    }));
    (this.buildLabel ? this.buildLabel({
      params: this.parameters,
      asCompany: Node.asCompany,
      companyFields: Node.companyFields
    }) : Promise.resolve('')).then(labelText => {
      this.label = new Text('', new TextStyle(style));
      this.trimLabelText(labelText);
      this.label.alpha = 0.8;
      const padding = 6;
      const iconHeight = 32;
      const width = 124;
      const offset = !labelText ? 18 : this.label.height > 14 ? 4 : 8;
      this.icon.x = Math.round(width / 2 - iconHeight / 2);
      this.icon.y = Math.round(width / 2 - iconHeight - this.text.height / 2 - padding + offset);
      this.text.x = Math.round(width / 2 - this.text.width / 2);
      this.text.y = Math.round(width / 2 - this.text.height / 2 + offset);
      this.label.x = Math.round(width / 2 - this.label.width / 2);
      this.label.y = Math.round(width / 2 + this.text.height / 2 + padding + offset);
      this.shape.addChild(this.icon);
      this.shape.addChild(this.text);
      this.shape.addChild(this.label);
    });
  };
  updateLabel = () => {
    const width = 124;
    if (this.buildLabel) {
      this.buildLabel({
        params: this.parameters,
        asCompany: Node.asCompany,
        companyFields: Node.companyFields
      }).then(labelText => {
        this.trimLabelText(labelText);
        const padding = 6;
        const iconHeight = 32;
        const offset = !labelText ? 18 : this.label.height > 14 ? 4 : 8;
        this.icon.y = Math.round(width / 2 - iconHeight - this.text.height / 2 - padding + offset);
        this.text.y = Math.round(width / 2 - this.text.height / 2 + offset);
        this.label.x = Math.round(width / 2 - this.label.width / 2);
        this.label.y = Math.round(width / 2 + this.text.height / 2 + padding + offset);
      });
    } else {
      this.text.x = Math.round(width / 2 - this.text.width / 2);
      this.text.y = Math.round(width / 2 - this.text.height / 2 + 18);
    }
  };
  willDestroy = () => {
    if (!this.noLink) this.nodeConnect.willDestroy();
    this.container.destroy();
  };
  isValidated = () => {
    try {
      return this._isValidated ? this._isValidated(this.parameters) : true;
    } catch {
      return false;
    }
  };
  onDelete = event => {
    if (!this.container.hasDragged && this.mode === MODES.CANVAS) {
      event.stopped = true;
      this.trigger('REMOVE_NODE', this);
      if (this.nodeValid.container.alpha === 1) {
        // Node was invalid but we're deleting it so bye bye
        this.trigger('VALID_NODE', this);
      }
    }
  };
  onSave = event => {
    if (!this.container.hasDragged && this.mode === MODES.CANVAS) {
      event.stopped = true;
      this.trigger('SAVE_NODE', this);
      this.container.dragging = false;
    }
  };
  onDuplicate = event => {
    if (!this.container.hasDragged && this.mode === MODES.CANVAS) {
      event.stopped = true;
      this.trigger('DUPLICATE_NODE', this);
      this.container.dragging = false;
    }
  };
  updateParameters = parameters => {
    this.parameters = parameters;
    if (this.parameters.name) {
      this.text.text = this.parameters.name;
    }
    this.updateLabel();
    const wasValidated = this.nodeValid.container.alpha === 0;
    const isValidated = this.isValidated();
    this.nodeValid.container.alpha = isValidated ? 0 : 1;
    if (wasValidated !== isValidated) {
      this.trigger(!isValidated ? 'INVALID_NODE' : 'VALID_NODE', this);
    }
    updateNode(this.id, {
      parameters
    });
  };
  updateOnEvent = (linkId, onEvent) => {
    this.onEvent = onEvent.id;
    updateLink(linkId, {
      on_event: this.onEvent
    });
  };
  onMouseOver = () => {
    if (!this.id) {
      return;
    }
    if (this.mode === MODES.CANVAS) {
      this.nodeDelete.container.alpha = 1;
      if (this.nodeSave) {
        this.nodeSave.container.alpha = 1;
      }
      this.nodeDuplicate.container.alpha = 1;
      if (!this.noLink) this.nodeConnect.container.alpha = 1;
    }
    if (this.mode === MODES.POINT_LINK && !this.isNodeFrom) {
      this.shapeBorder.alpha = 1;
    }
  };
  onMouseOut = () => {
    this.nodeDelete.container.alpha = 0;
    if (this.nodeSave) {
      this.nodeSave.container.alpha = 0;
    }
    this.nodeDuplicate.container.alpha = 0;
    this.shapeBorder.alpha = 0;
    if (this.mode === MODES.POINT_LINK) {} else {
      if (!this.noLink) this.nodeConnect.container.alpha = 0;
    }
  };
  onDragStart = event => {
    this.container.dragging = true;
    this.container.movement = 0;
    if (this.mode === MODES.CANVAS) {
      this.data = event.data;
      sx = this.data.getLocalPosition(this.container).x * this.container.scale.x;
      sy = this.data.getLocalPosition(this.container).y * this.container.scale.y;
      this.trigger('SORT_NODES', this);
    }
  };
  onDragEnd = event => {
    if (this.container.dragging &&
    // Did we start mouse down
    !this.container.movement && (
    // Did we NOT move it
    this.mode === MODES.CANVAS || this.mode === MODES.NODE_PARAM) && (
    // Are we in canvas mode
    this.component || this.componentParams) &&
    // Does this node have paramters
    event.type === 'pointerup' // Was this a direct (not outside) mouse up event
    ) {
      // Open parameter modal
      this.trigger('EDIT_PARAM', this, this.mode);
    }
    if (this.container.dragging &&
    // Did we start mouse down
    !this.container.movement &&
    // Did we NOT move it
    this.mode === MODES.PAN && (
    // Are we in PAN mode
    this.component || this.componentParams) &&
    // Does this node have paramters
    event.type === 'pointerup' &&
    // Was this a direct (not outside) mouse up event
    event.data.button != '2' // Did we NOT right click?
    ) {
      // Open parameter modal
      this.trigger('EDIT_PARAM', this, this.mode);
    }
    if (!this.container.dragging &&
    // Did we start somewhere else with mouse down
    event.type === 'pointerup' // Did we directly stop here
    ) {
      // We might be adding a link.  We need to check higher up if we have a "from node"
      this.trigger('ADD_LINK', this);
    }
    if (this.container.dragging) {
      this.container.dragging = false;
      this.container.hasDragged = false;
      this.data = null;
      if (this.snapToGrid) {
        const containerInX = Math.round(this.container.x / 25) * 25;
        const containerInY = Math.round(this.container.y / 25) * 25;
        this.container.x = containerInX;
        this.container.y = containerInY;
      }
      if (this.initialPosition.x !== this.container.x || this.initialPosition.y !== this.container.y) {
        this.initialPosition = {
          x: this.container.x,
          y: this.container.y
        };
        updateNode(this.id, this.initialPosition);
      }
    }
    this.container.movement = null;
  };
  onDragMove = event => {
    // Chris -- I added a check to make sure there is movement in at least one direction before we mark the "hasDragged".
    // Otherwise I was having problems where I couldn't delete a node. Instead it it was opening the paramters.
    // It might be a Windows thing.
    if (this.data && this.container.dragging && (event.data.originalEvent.movementX !== 0 || event.data.originalEvent.movementY !== 0)) {
      this.container.hasDragged = true;
      const newPosition = this.data.getLocalPosition(this.container.parent);
      const x = newPosition.x - sx;
      const y = newPosition.y - sy;
      this.container.movement = (Math.abs(this.container.x - x) + Math.abs(this.container.y - y)) * 100;
      this.container.x = Math.round(x);
      this.container.y = Math.round(y);
      this.trigger('UPDATE_NODE_LINKS', this);
    }
  };
}