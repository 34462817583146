import React, { useState, useContext, useEffect } from 'react';
import { Scoped, k } from 'kremling';
import { toasterService } from '../../components/toaster/toaster-service';
import cinchLogo from '../../assets/img/cinch-logo.svg';
import { Button } from '../../components/button/button.component';
import { getMe, validateMFA } from '../../shared/auth.api';
import signInImg from '../../assets/img/sign-in-pattern.jpg';
import { UserStateContext } from 'context/user-state-context';
export const MFA = ({
  history,
  location
}) => {
  const {
    token,
    setUser,
    logOut,
    setNeedsMFA
  } = useContext(UserStateContext);
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({
    code: false
  });
  const [redirect, setRedirect] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has('redirect')) {
      setRedirect(params.get('redirect'));
    }
  }, []);
  useEffect(() => {
    if (!token) {
      history.push('/sign-in');
    }
  }, [token]);
  const setLogo = () => ({
    __html: cinchLogo
  });
  const handleSubmit = event => {
    event.preventDefault();
    if (validInputs()) {
      setIsLoading(true);
      validateMFA(code, rememberMe, localStorage.getItem('deviceId')).then(() => getMe()).then(({
        data: user
      }) => {
        setNeedsMFA(false);
        setUser(user);
        history.push(redirect || '/companies');
      }).catch(() => {
        toasterService.error('Invalid multi-factor authentication code.');
        setIsLoading(false);
      });
    }
  };
  const validInputs = () => {
    if (!code || code.length < 6 || code.length > 6) {
      setFormErrors(original => ({
        ...original,
        code: true
      }));
      return false;
    }
    setFormErrors({
      code: false
    });
    return true;
  };
  return <Scoped css={css}>
      <div className="wrapper-contain">
        <div className="sign-in__wrapper" style={{
        backgroundImage: `url(${signInImg})`
      }}>
          <div className="sign-in">
            <form onSubmit={handleSubmit}>
              <div className="text-center">
                <div className="mb-5">
                  <div className="logo" dangerouslySetInnerHTML={setLogo()} />
                </div>
              </div>
              <p>Please enter your multi-factor authentication code</p>
              <div className="form-group">
                <label>Code</label>
                <input id="iSignInMFA" className="form-control" onChange={event => setCode(event.target.value)} value={code} autoFocus />
                {formErrors.code === true ? <div>
                    <p className="invalid-input mt-2">Invalid code</p>
                  </div> : null}
              </div>
              <div className="form-group">
                <div className="checkbox-label">
                  <label className="form-check-label">
                    <input id="iSignInRememberMe" className="form-check-input" name="rememberMe" type="checkbox" value={rememberMe} htmlFor="rememberMe" onChange={event => setRememberMe(event.target.checked)} />
                    <span className="m-2">Remember this device</span>
                  </label>
                </div>
              </div>
              <div className="mt-5">
                <Button disabled={isLoading} id="bSignInSignIn" type="submit" actionType="primary" block large>
                  Sign In
                </Button>
              </div>
              <div className="mt-5">
                <a href="#" onClick={logOut}>
                  Log Out
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Scoped>;
};
const css = {
  styles: `[kremling="i4"] body,body[kremling="i4"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i4"] .sign-in__wrapper,[kremling="i4"].sign-in__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-position: center;
}

[kremling="i4"] .sign-in,[kremling="i4"].sign-in {
  background-color: #fff;
  padding: 5rem 2rem;
  border-radius: 2rem;
  box-shadow: var(--box-shadow-5);
  flex-grow: 1;
}
@media (min-width: 768px) {
  [kremling="i4"] .sign-in,[kremling="i4"].sign-in {
    border-radius: 1rem;
    padding: 5rem 3rem;
    width: 36rem;
    flex-grow: 0;
  }
}

[kremling="i4"] .logo,[kremling="i4"].logo {
  fill: var(--color-primary);
  width: 100px;
  margin: 0 auto;
}

[kremling="i4"] .request,[kremling="i4"].request {
  color: #12aae1;
  font-size: 16px;
  line-height: 20px;
  text-decoration: underline;
}

[kremling="i4"] .bg-wrapper,[kremling="i4"].bg-wrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

[kremling="i4"] .bg-wrapper video,[kremling="i4"].bg-wrapper video {
  min-width: 100vw;
  min-height: 100vh;
}

[kremling="i4"] .remember-me,[kremling="i4"].remember-me {
  color: #8f8f8f;
  font-size: 16px;
  line-height: 20px;
}

[kremling="i4"] .best,[kremling="i4"].best {
  color: #646464;
  font-size: 16px;
  line-height: 20px;
}

[kremling="i4"] .btn-sm,[kremling="i4"].btn-sm {
  font-weight: normal;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

[kremling="i4"] .forgot-btn,[kremling="i4"].forgot-btn {
  position: absolute;
  right: 6px;
  top: 37px;
  color: #a5a5a5;
  font-size: 12px;
  text-transform: inherit;
  border-radius: 2px;
  background-color: #e3e3e3;
}

[kremling="i4"] .filter,[kremling="i4"].filter {
  z-index: 100;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
}

[kremling="i4"] .checkbox-box,[kremling="i4"].checkbox-box {
  float: left;
  width: 30px;
}

[kremling="i4"] .checkbox-label,[kremling="i4"].checkbox-label {
  float: left;
  margin-bottom: 13px;
  margin-top: -5px;
}

[kremling="i4"] .width-240,[kremling="i4"].width-240 {
  width: 240px;
}

[kremling="i4"] .invalid-input,[kremling="i4"].invalid-input {
  margin-bottom: 0px;
  color: red;
}`,
  id: 'i4',
  namespace: 'kremling'
};