import React, { useContext, useEffect } from 'react';
import { Box, Typography, Stack, TextField, InputAdornment } from '@mui/material';
import { history } from '../../../root.component';
const tabsInfo = [
/*   {
  key: 'all',
  name: 'All',
}, */

{
  key: 'journey',
  name: 'Journeys'
}, {
  key: 'segment',
  name: 'Segments'
}, {
  key: 'email',
  name: 'Emails'
}, {
  key: 'text',
  name: 'Texts'
}, {
  key: 'mailer',
  name: 'Mailers'
}, {
  key: 'survey',
  name: 'Surveys'
}];
export const PrebuiltInstallsNavigationTabs = props => {
  const handleTabsChange = (event, index) => {
    if (tabsInfo[index].key === 'all') {
      history.push(`/prebuilt/install`);
      return;
    }
    history.push(`/prebuilt/install/${tabsInfo[index].key}`);
  };
  return <Box sx={{
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }}>
      <Stack sx={{
      borderRadius: '32px',
      backgroundColor: '#fff',
      p: '16px'
    }} spacing="15px" direction={'row'}>
        {tabsInfo.map((tab, index) => <Box key={tab.key} sx={{
        cursor: 'pointer',
        textTransform: 'none',
        height: '32px',
        borderRadius: '16px',
        px: '20px',
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        backgroundColor: props?.currentTab == tab.key ? '#C2E7FF' : '#fff',
        '&:hover': {
          backgroundColor: '#C2E7FF'
        }
      }} onClick={e => {
        handleTabsChange(e, index);
      }}>
            <Typography sx={{
          color: props?.currentTab == tab.key ? '#3898D9' : '#1D252D'
        }}>
              {tab.name}
            </Typography>
          </Box>)}
      </Stack>
    </Box>;
};